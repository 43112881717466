<template>
  <div class="home">
    <v-container>
      <v-row v-for="(item,index) in items" :key="index">
        <CardCheckout
        v-if="item.fare_type_buy == 'flatefare'" 
        :fareName="item.fare_name"
        :route="item.route_name"
        :original="item.fare_origin_name"
        :destination="item.fare_destination_name"
        :price="item.cart_original_price"
        :currency="item.fare_currency"
        />
        <CardCheckoutMultidays
        v-if="item.fare_type_buy == 'multidays'" 
        :fareName="item.fare_name"
        :route="item.route_name"
        :original="item.fare_origin_name"
        :destination="item.fare_destination_name"
        :price="item.cart_original_price"
        :currency="item.fare_currency"
        />
      </v-row>
      
    </v-container>
    <br><br><br><br><br><br>
    <div class="footer">
      <v-container>
      <p class="num-135000-v-nd">{{ checkoutTotalPrice }} {{ checkoutCurrency }}</p>
      <p class="title-total">Total</p>
      <p class="flat-fare-ticket-is-valid-for-24-hours">Single Trip ticket is valid for 24 hours</p>
        <v-btn 
          rounded
          width="80%"
          class="btn-cart"
          color="primary"
          @click="addToCheckout()">
          Pay
        </v-btn>
      </v-container>
    </div>
  </div>
</template>

<script>
import CardCheckout from "@/components/CardCheckout.vue";
import CardCheckoutMultidays from "@/components/CardCheckoutMultidays.vue";

export default {
  name: "Checkout",
  components: {
    CardCheckout,
    CardCheckoutMultidays,
  },
  data() {
    return {
      checkoutTotalPrice:0,
      checkoutCurrency:"VNĐ",
      colors: [
        "green",
        "secondary",
        "yellow darken-4",
        "red lighten-2",
        "orange darken-1",
      ],
      cycle: false,
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
      items:[
        {
            fare_type_buy: "flatefare",
            fare_id: 119,
            cart_qty: 2,
            cart_original_price: 10000,
            promo_id: 0,
            fare_merchant_id: "3",
            fare_merchant_name: "HCMC Operator 1",
            fare_name: "Single Trip OP1",
            fare_currency: "VNĐ",
            fare_value: "5000",
            original_value: "5000",
            promotion_name: "",
            promotion_type: "",
            promotion_value: "",
            fare_origin_id: "15",
            fare_destination_id: "17",
            fare_origin_name: "OP1-MCPT-Location A",
            fare_destination_name: "OP1-MCPT-Location C",
            fare_start_date: "2020-07-01 01:02:00",
            fare_end_date: "2020-07-31 01:02:00",
            fare_type: "01",
            created_at: "2020-07-29 08:03:23",
            updated_at: "2020-07-29 08:03:55",
            deleted_at: "",
            fare_user_updated: "HCMC-Operator1",
            fare_uuid: "55505066-bb91-4094-a5df-b06e20a9afb7",
            max_trip: "",
            max_trip_perday: "",
            valid_days: "",
            buy_time_as_expired: "",
            route_id: "1",
            route_name: "route 2"
        },
        {
            fare_type_buy: "multidays",
            fare_id: 120,
            cart_qty: 2,
            cart_original_price: 12000,
            promo_id: 0,
            fare_merchant_id: "3",
            fare_merchant_name: "HCMC Operator 1",
            fare_name: "Multidays Ticket OP1",
            fare_currency: "VNĐ",
            fare_value: "6000",
            original_value: "6000",
            promotion_name: "",
            promotion_type: "",
            promotion_value: "",
            fare_origin_id: "15",
            fare_destination_id: "17",
            fare_origin_name: "OP1-MCPT-Location A",
            fare_destination_name: "OP1-MCPT-Location C",
            fare_start_date: "2020-07-01 01:02:00",
            fare_end_date: "2020-07-31 01:02:00",
            fare_type: "01",
            created_at: "2020-07-29 08:03:23",
            updated_at: "2020-07-29 08:03:55",
            deleted_at: "",
            fare_user_updated: "HCMC-Operator1",
            fare_uuid: "55505066-bb91-4094-a5df-b06e20a9afb7",
            max_trip: "",
            max_trip_perday: "",
            valid_days: "",
            buy_time_as_expired: "",
            route_id: "1",
            route_name: "route 2"
        }],
    };
  },
  mounted(){
    this.checkoutTotalPrice = 0;
      for (let item of this.items){
        this.checkoutTotalPrice+=item.cart_original_price ;
      }
  },
  methods:{
    addToCheckout(){
      this.$router.push({ name: "ResultTicket" });
    },
    toCartPage(){
      this.$router.push({ name: "Cart" });
    },
},
};
</script>

<style lang="scss" scoped>

.title{
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 118.88%;
  /* identical to box height, or 24px */

  text-transform: capitalize;
  color: #000000;
}

.desc-title{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 118.88%;
  /* or 14px */

  text-align: center;

  color: #000000;

}

/* If the screen size is 673px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
 
.flat-fare {
  background: url('../../assets/buy-ticket/flat.png') no-repeat ;
  background-size: cover;
  width: 100%;
  height: 500px;
}

.rounded-card {
  border-radius: 15px !important;
  background-color:#e9f9eb;
} 

}

/* If the screen size is 672 or less, set the font-size of <div> to 30px */
@media only screen and (max-width: 600px) {


.flat-fare {
  background: url('../../assets/buy-ticket/flat.png');
  // border-radius: 15px !important;
  // background-color:#e9f9eb;
  width: 100%;
  height: 190px;
  // margin-right: 20%;
  left: 16px;
  // top: 229px;
  // background: linear-gradient(180deg, #FFFFFF 0%, #FFE9FC 100%);
}

.rounded-card {
  // background-image: require('../../assets/photo-profile.png');
  background-color:#e9f9eb;
  border-radius: 15px !important;
  // background: linear-gradient(180deg, #FFFFFF 0%, #FFE9FC 100%);
}

}
.footer {
  margin-top: 25px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: #FFFFFF;
  mix-blend-mode: normal;
  /* Field */

  box-shadow: 0px 1px 10px 0px
}

.num-135000-v-nd {
  // @include roboto-14-medium;
  font-size: 14px;
  color: rgba(242, 153, 0, 1);
  margin-top: 4px;
  margin-right: 9px;
}

.btn-cart{

  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}
.title-total{
  // margin-top: -1%;
  margin-bottom: 1%;
  
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */


  color: #000000;
}

.flat-fare-ticket-is-valid-for-24-hours {
  // @include roboto-8-light;
  font-size: 11px;
  color: #ADB3BC;
  margin-bottom: 1%;
  font-style: italic;
}
</style>