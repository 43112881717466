<template>
  <div class="component-2">
      <v-row align="left">
        <v-col>
            <p class="route">
              {{ route }}
            </p>
        </v-col>
      </v-row>

    <!-- <v-row align="center">
      <v-col align="center"> -->
    <div  class="flex-wrapper-five">
      <p class="bth">{{ fareName }}</p> <br>
      <!-- <div class="relative-wrapper-one"> -->
        <img
          alt=""
          class="img-bus"
          :src="require('@/assets/assetsCardTicketFlate/bus-img.svg')"
        />
      <!-- </div> -->
    </div>
    <div align="center" class="flex-wrapper-six">
      <p class="bn-thanh">{{ describe }}</p>
    </div>
      <!-- </v-col>
    </v-row> -->
    <div class="flex-wrapper-seven">
      <p class="total-passenger">{{ $t('TotalTicket') }}</p>
      <div class="flex-wrapper-nine">
      </div>
      <div class="flex-wrapper-one">
          <input class="label input-label" v-model="newValue" disabled />
      </div>
    </div>
    <!-- <v-row>
        <v-col cols="4" align="left">
        </v-col>

        <v-col cols="4" align="left">
          <p class="ticket-value-price">{{ newValue }}x  {{ price }}</p>
        </v-col>

        <v-col cols="4" align="right">
          <p class="num-135000-v-nd ">{{ price }} {{ currency }}</p>
        </v-col>
    </v-row> -->
    <div class="flex-wrapper-seven">
          <p class="ticket-price">{{$t('TicketPrice')}} </p>
          <p class="ticket-value-price">{{ newValue }}x  {{ price }}</p>
          <p class="num-135000-v-nd ">{{ price }} {{ currency }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardCheckoutMultidays",
  props: {
    fareName: {
      type: String,
      default: "Route 1 Bến Thành - Bến Xe buýt Chợ Lớn"
    },
    route: { type: String, default: "Multi-day Ticket - " },
    orig: { type: String, default: "BTH" },
    dest: { type: String, default: "BBC" },
    describe: { type: String, default: "Total 6 trip for 3 days - 2 trip/day" },
    destination: {
      type: String,
      default: "Bến Xe buýt Chợ Lớn"
    },
    min: {
      default: 1,
      type: Number
    },
    max: {
      default: undefined,
      type: Number
    },
    newValue: { type: Number, default: 1 },
    value: {
      default: 1,
      type: Number
    },
    label: { type: String, default: "1" },
    price: { type: String, default: "135,000" },
    currency: { type: String, default: "VNĐ" },
  },

  methods:{
    // clickDelete() {
    //   console.log("delete")
    //   this.delete()
    // },
    mpplus: function () {
      console.log("mpplus")
      if (this.max === undefined || (this.newValue < this.max)) {
        this.newValue = this.newValue + 1
        this.$emit('input', this.newValue)
      }
    },
    mpminus: function () {
      console.log("mnmin")
      if ((this.newValue) > this.min) {
        this.newValue = this.newValue - 1
        this.$emit('input', this.newValue)
      }else{
        this.buy = false
      }
    }
  },

  watch: {
      value: {
      handler: function (newVal) {
        this.newValue = newVal
      }
    }
  },
    created: function () {
      this.newValue = this.value
    },
};
</script>

<style lang="scss" scoped>

/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .component-2 {
    margin-bottom: 20px;
    background-color: #FFFFFF;
    padding: 11px 23px 18px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .route {
    font-size: 14px;
    color: #000000;
    margin-bottom: 12px;
    align-items: left;
  }
  .flex-wrapper-five {
    // padding: 0 0 0 33px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }
  .bth {
    font-size: 26px;
    color: rgba(72, 158, 75, 1);
  }
  .relative-wrapper-one {
    // margin-right: 31px;
    // position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    // align-content: center;
  }
  .img-bus {
    width: 25%;
    // position: relative;
    margin-left: 35%;
    margin-bottom: 10px;
  }
  .group {
    position: absolute;
    left: 55px;
    top: -1px;
    transform: rotate(180deg);
  }
  .flex-wrapper-six {
    // margin-bottom: 18px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }
  .bn-thanh {
    font-size: 10px;
    color: rgba(75, 177, 78, 1);
    // &:not(:last-of-type) {
    //   margin-right: 195px;
    // }
  }
  .passenger {
    font-size: 14px;
    color: #000000;
    margin-bottom: 4px;
  }
  .flex-wrapper-seven {
    display: flex;
    align-items: flex-start;
    &:not(:last-of-type) {
      margin-bottom: 23px;
    }
  }


  .total-passenger {
    font-size: 12px;
    color: rgba(134, 134, 134, 1);
    margin-top: 8px;
    margin-right: 192px;
  }

  .flex-wrapper-nine {
    margin-right: 7px;
    padding: 4px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .vector-two {
    width: 15px;
    height: 4px;
    background-color: #FC6276;
    margin-bottom: 1px;
  }
  .flex-wrapper-four {
    background-color: #FC6276;
    padding: 3px 3px 2px;
    display: flex;
    align-items: center;
  }
  .vector-three {
    width: 1px;
    height: 8px;
    background-color: #FC6276;
    &:not(:last-of-type) {
      margin-right: 2px;
    }
  }
  .flex-wrapper-one {
    border-radius: 23px;
    width: 90px;
    padding: 2px 6px 2px 4px;
    position: relative;
    height: 29px;
    border: 1px solid rgba(75, 177, 78, 0.35);
  }
  .flex-wrapper-two {
    background-color: #FFFFFF;
    border-radius: 50%;
    padding: 9px 9px 10px 10px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 46px;
    top: -1px;
    border: 1px solid #4BB14E;
  }
  .flex-wrapper-three {
    background-color: #FFFFFF;
    border-radius: 50%;
    padding: 12px 10px 13px 8px;
    display: flex;
    align-items: center;
    position: absolute;
    left: -1px;
    top: -1px;
    border: 1px solid #4BB14E;
  }
  .hyphen {
    width: 9px;
    height: 2px;
    background-color: #4BB14E;
  }
  .label {
    width: 64px;
    font-size:10px;
    color: rgba(75, 177, 78, 1);
    text-align: center;
    position: relative;
  }
  .ticket-price {
    font-size:14px;
    color: #000000;
    font-weight: bold;
    margin-right: 17px;
  }
  .ticket-value-price {
    font-size:14px;
    color: #000000;
    font-weight: bold;
    margin-right: 140px;
  }
  
  .num-135000-v-nd {
    font-size:14px;
    color: rgba(242, 153, 0, 1);
  }

  .btn-plus{
    cursor: pointer;
    top: -5px;
    height: 30px;
    width: 30px;
    left: 60px;
  }
  .btn-minus{
    cursor: pointer;
    top: -5px;
    height: 30px;
    width: 30px;
    left: -35px;
  }
  .icon-plus{
    height: 6px;
    width: 1px;
    left: -1px;
  }
  .icon-minus{
    height: 6px;
    width: 1px;
    left: -1px;
  }
  .onHover{
    cursor: pointer;
  }
  .input-label{
    left: 10px;
    // top: -27px;
  }
  .btn-delete{
    cursor: pointer;
    background: rgba(255,255, 255, 0.9);;
  }
}


/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .component-2 {
    margin-bottom: 20px;
    background-color: #FFFFFF;
    padding: 11px 23px 18px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .route {
    font-size: 14px;
    color: #000000;
    margin-bottom: 12px;
    align-items: left;
  }
  .flex-wrapper-five {
    // padding: 0 0 0 33px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }
  .bth {
    font-size: 26px;
    color: rgba(72, 158, 75, 1);
    // &:not(:last-of-type) {
    //   margin-right: 30px;
    // }
  }
  .relative-wrapper-one {
    // margin-right: 31px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }
  .img-bus {
    width: 25%;
    margin-left: 35%;
    margin-bottom: 10px;
  }
  .group {
    position: absolute;
    left: 55px;
    top: -1px;
    transform: rotate(180deg);
  }
  .flex-wrapper-six {
    // margin-bottom: 18px;
    // padding: 0 0 0 35px;
    // display: flex;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }
  .bn-thanh {
    font-size: 10px;
    color: rgba(75, 177, 78, 1);
    // &:not(:last-of-type) {
    //   margin-right: 195px;
    // }
  }
  .passenger {
    font-size: 14px;
    color: #000000;
    margin-bottom: 4px;
  }
  .flex-wrapper-seven {
    display: flex;
    align-items: flex-start;
    &:not(:last-of-type) {
      margin-bottom: 23px;
    }
  }

  .total-passenger {
    font-size: 12px;
    color: rgba(134, 134, 134, 1);
    margin-top: 8px;
    margin-right: 192px;
  }


  .flex-wrapper-nine {
    margin-right: 7px;
    padding: 4px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .vector-two {
    width: 15px;
    height: 4px;
    background-color: #FC6276;
    margin-bottom: 1px;
  }
  .flex-wrapper-four {
    background-color: #FC6276;
    padding: 3px 3px 2px;
    display: flex;
    align-items: center;
  }
  .vector-three {
    width: 1px;
    height: 8px;
    background-color: #FC6276;
    &:not(:last-of-type) {
      margin-right: 2px;
    }
  }
  .flex-wrapper-one {
    border-radius: 23px;
    width: 90px;
    padding: 2px 6px 2px 4px;
    position: relative;
    height: 29px;
    border: 1px solid rgba(75, 177, 78, 0.35);
  }
  .flex-wrapper-two {
    background-color: #FFFFFF;
    border-radius: 50%;
    padding: 9px 9px 10px 10px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 46px;
    top: -1px;
    border: 1px solid #4BB14E;
  }
  .flex-wrapper-three {
    background-color: #FFFFFF;
    border-radius: 50%;
    padding: 12px 10px 13px 8px;
    display: flex;
    align-items: center;
    position: absolute;
    left: -1px;
    top: -1px;
    border: 1px solid #4BB14E;
  }
  .hyphen {
    width: 9px;
    height: 2px;
    background-color: #4BB14E;
  }
  .label {
    width: 64px;
    font-size:10px;
    color: rgba(75, 177, 78, 1);
    text-align: center;
    position: relative;
  }
  .ticket-price {
    font-size:10px;
    color: #000000;
    font-weight: bold;
    margin-right: 25px;
  }

  .ticket-value-price {
    font-size:10px;
    color: #000000;
    font-weight: bold;
    margin-right: 150px;
  }

  .num-135000-v-nd {
    font-size:10px;
    color: rgba(242, 153, 0, 1);
  }

  .btn-plus{
    cursor: pointer;
    top: -5px;
    height: 30px;
    width: 30px;
    left: 60px;
  }
  .btn-minus{
    cursor: pointer;
    top: -5px;
    height: 30px;
    width: 30px;
    left: -35px;
  }
  .icon-plus{
    height: 6px;
    width: 1px;
    left: -1px;
  }
  .icon-minus{
    height: 6px;
    width: 1px;
    left: -1px;
  }
  .onHover{
    cursor: pointer;
  }
  .input-label{
    left: 10px;
    // top: -27px;
  }
  .btn-delete{
    cursor: pointer;
    background: rgba(255,255, 255, 0.9);;
  }
}
</style>